<template>
  <el-table
    :data="tableData"
    height="100%"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    class="list"
    :row-class-name="rowClass"
  >
    <el-table-column type="selection" />
    <el-table-column :label="firstColumnLabel">
      <template v-slot="{ row }">
        <router-link
          :to="{ name: 'Pad', params: { token: row.padID } }" custom
          v-slot="{ href }">
          <el-link
            :href="href"
            @click.native="handleOpen"
            :icon="row.icon"
            :disabled="!canOpen"
            :underline="false">
            {{ row.name }}
          </el-link>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      v-for="col in tableColumns"
      :key="col.prop"
      :prop="col.prop"
      :label="col.label"
      show-overflow-tooltip
    />
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    firstColumnLabel: {
      type: String,
      required: true
    },
    tableColumns: {
      type: Array,
      required: true
    },
    canOpen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      multipleSelection: []
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val
      this.$emit('selection-change', this.multipleSelection)
    },
    rowClass ({ row }) {
      const ok = this.multipleSelection.some((selection) => {
        return selection.padID === row.padID
      })
      if (ok) {
        return 'select-row-color'
      }
    },
    handleOpen () {
      this.$emit('open')
    }
  }
}
</script>

<style scoped>
.el-table /deep/ .select-row-color {
  background-color: rgb(236, 245, 255) !important;
}
.el-table /deep/ tbody tr:hover > td {
  background-color: rgb(236, 245, 255) !important;
}
</style>
