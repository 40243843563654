<template>
  <el-container class="home-container">
    <el-aside width="auto">
      <HomeAside :collapse="isCollapse" />
    </el-aside>
    <el-container class="right-content">
      <el-header class="home-header">
        <HomeHeader v-model="isCollapse" />
      </el-header>
      <el-main class="home-main">
        <transition>
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HomeHeader from '@/views/Home/HomeHeader'
import HomeAside from '@/views/Home/HomeAside'

export default {
  name: 'Home',
  components: {
    HomeHeader,
    HomeAside
  },
  watch: {
    isCollapse: function (collapse) {
      if (collapse) localStorage.setItem('collapse', 'true')
      else localStorage.removeItem('collapse')
    }
  },
  data () {
    const isCollapse = !!localStorage.getItem('collapse')
    return {
      isCollapse: isCollapse
    }
  }
}
</script>

<style scoped>
.home-container {
  height: 100vh;
}
.home-header {
  padding: 0 !important;
}
.home-main {
  padding: 0 !important;
  overflow-y: hidden;
}

@supports (-webkit-touch-callout: none) {
  .home-container {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
</style>
