<template>
  <el-button type="primary" @click="handleClick" plain>重命名文档</el-button>
</template>

<script>
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  props: {
    padId: String,
    padName: String
  },
  methods: {
    async handleClick () {
      console.log('padID:', this.padId, 'padName:', this.padName)
      try {
        await this.$prompt('文档名称', '重命名文档', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: this.padName,
          inputPattern: /^.{1,50}$/,
          inputErrorMessage: '文档名称太短或太长 ',
          beforeClose: async (action, instance, done) => {
            if (action !== 'confirm') {
              done()
              return
            }
            const padName = instance.inputValue
            if (padName === this.padName) {
              done()
              this.$message.info('文档名称未改变')
              return
            }
            instance.showCancelButton = false
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '修改中'
            setTimeout(async () => {
              try {
                await api.renamePad(this.padId, padName)
                this.$emit('renamed')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                    this.$message.success('重命名成功！')
                  }, 300)
                }, 300)
              } catch (error) {
                console.log('error:', error)
                dealOtherError(this, error)
              }
            }, 1000)
          }
        })
      } catch {
        this.$message.info('取消重命名')
      }
    }
  }
}
</script>

<style scoped>

</style>
