<template>
  <div class="home-header">
    <base-row class="header-container">
      <BaseSwitchArrow
        :collapse="value"
        @collapse-change="$emit('input', $event)"
      />
      <BaseAvatarSelf />
    </base-row>
  </div>
</template>

<script>
import BaseSwitchArrow from '@/components/BaseSwitchArrow'
import BaseAvatarSelf from '@/components/BaseAvatarSelf'

export default {
  components: {
    BaseSwitchArrow,
    BaseAvatarSelf
  },
  props: {
    value: Boolean
  }
}
</script>

<style scoped>
.home-header {
  height: 100%;
}
.header-container {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 2px 4px;
}
</style>
