<template>
  <BaseListMain
    v-bind="listData"
    @selection-change="handleSelectionChange"
    @open="handleRefresh"
  >
    <template v-slot:header>
      <base-row fullWidth>
        <span>我的文档</span>
        <el-row>
          <BaseButtonNewPad @created="handleRefresh" />
        </el-row>
      </base-row>
    </template>
    <template v-slot:footer>
      <base-row justify="start">
        <BaseButtonRenamePad
          v-if="selectedData.length === 1"
          :pad-id="selectedData[0].padID"
          :pad-name="selectedData[0].name"
          @renamed="handleRefresh(0)" />
        <BaseButtonTrashPads
          :pad-ids="selectedData.map(item => item.padID)"
          @trashed="handleRefresh(0)" />
      </base-row>
    </template>
  </BaseListMain>
</template>

<script>
import BaseListMain from '@/components/BaseListMain'
import BaseButtonNewPad from '@/components/BaseButtonNewPad'
import BaseButtonRenamePad from '@/components/BaseButtonRenamePad'
import BaseButtonTrashPads from '@/components/BaseButtonTrashPads'
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  components: {
    BaseListMain,
    BaseButtonNewPad,
    BaseButtonRenamePad,
    BaseButtonTrashPads
  },
  data () {
    return {
      listData: {
        tableData: [],
        firstColumnLabel: '文档',
        tableColumns: [
          {
            prop: 'owner',
            label: '归属者'
          },
          {
            prop: 'createdTime',
            label: '创建时间'
          },
          {
            prop: 'lastEditedTime',
            label: '最近编辑时间'
          }
        ],
        loading: true,
        emptyLabel: '没有文档，快尝试新建文档'
      },
      selectedData: []
    }
  },
  mounted () {
    this.listData.loading = true
    this.getList()
  },
  methods: {
    handleSelectionChange (val) {
      console.log('selection change: ', val)
      this.selectedData = val
    },
    async getList () {
      try {
        const resp = await api.listOwnPads()
        const metas = resp.data.padMetas
        const { data: { userInfo } } = await api.getUserSelfInfo()
        const tableData = []
        metas.sort((aMeta, bMeta) => {
          return bMeta.lastEditedTime - aMeta.lastEditedTime
        }).forEach(meta => {
          tableData.push({
            name: meta.padName,
            padID: meta.padID,
            icon: 'el-icon-document',
            owner: userInfo.nickname,
            createdTime: (new Date(meta.createdTime)).toLocaleString(),
            lastEditedTime: (new Date(meta.lastEditedTime)).toLocaleString()
          })
        })
        this.listData.tableData = tableData
        setTimeout(() => {
          this.listData.loading = false
        }, 500)
      } catch (error) {
        setTimeout(() => {
          dealOtherError(this, error)
          this.listData.emptyLabel = '出错了！尝试刷新一下'
          this.listData.loading = false
        }, 500)
      }
    },
    handleRefresh (timeout = 3000) {
      setTimeout(() => this.getList(), timeout)
    }
  }
}
</script>

<style scoped>

</style>
