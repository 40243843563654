<template>
  <BaseListMain
    v-bind="listData"
    @selection-change="handleSelectionChange"
    @open="handleRefresh"
  >
    <template v-slot:header>
      <base-row fullWidth>
        <span>最近列表</span>
        <el-row>
          <BaseButtonNewPad @created="handleRefresh(5000)" />
        </el-row>
      </base-row>
    </template>
    <template v-slot:footer>
      <base-row justify="start">
        <BaseButtonDeleteVisitedPads :pad-ids="selectedPadIDs" @deleted="handleDelete" />
      </base-row>
    </template>
  </BaseListMain>
</template>

<script>
import BaseListMain from '@/components/BaseListMain'
import BaseButtonNewPad from '@/components/BaseButtonNewPad'
import BaseButtonDeleteVisitedPads from '@/components/BaseButtonDeleteVisitedPads'
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  components: {
    BaseListMain,
    BaseButtonNewPad,
    BaseButtonDeleteVisitedPads
  },
  data () {
    return {
      listData: {
        tableData: [],
        firstColumnLabel: '文档',
        tableColumns: [
          {
            prop: 'owner',
            label: '归属者'
          },
          {
            prop: 'time',
            label: '最近打开时间'
          }
        ],
        loading: true,
        emptyLabel: '无浏览记录，快尝试打开文档'
      },
      selectedPadIDs: []
    }
  },
  mounted () {
    this.listData.loading = true
    this.getList()
  },
  methods: {
    handleSelectionChange (val) {
      console.log('selection change: ', val)
      this.selectedPadIDs = val.map(item => item.padID)
    },
    async getList () {
      try {
        const resp = await api.listVisitedPads()
        const metas = resp.data.padMetas
        const userNames = new Map()
        for (const { owner } of metas) {
          if (userNames.has(owner)) continue
          const resp = await api.getUserBasicInfo(owner)
          userNames.set(owner, resp.data.userInfo.nickname)
        }
        const tableData = []
        metas.forEach(meta => {
          tableData.push({
            name: meta.padName,
            padID: meta.padID,
            icon: 'el-icon-document',
            owner: userNames.get(meta.owner),
            time: (new Date(meta.lastVisitedTime)).toLocaleString()
          })
        })
        this.listData.tableData = tableData
        setTimeout(() => {
          this.listData.loading = false
        }, 500)
      } catch (error) {
        setTimeout(() => {
          dealOtherError(this, error)
          this.listData.emptyLabel = '出错了！尝试刷新一下'
          this.listData.loading = false
        }, 500)
      }
    },
    handleRefresh (timeout = 3000) {
      setTimeout(() => this.getList(), timeout)
    },
    handleDelete () {
      this.handleRefresh(100)
    }
  }
}
</script>

<style scoped>

</style>
