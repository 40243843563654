<template>
  <el-link :underline="false" :icon="collapseIcon" @click="handleClick" />
</template>

<script>
export default {
  model: {
    prop: 'collapse',
    event: 'collapse-change'
  },
  props: {
    collapse: Boolean
  },
  data () {
    return {
      isCollapse: this.collapse
    }
  },
  computed: {
    collapseIcon: function () {
      return this.isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'
    }
  },
  methods: {
    handleClick () {
      this.isCollapse = !this.isCollapse
      this.$emit('collapse-change', this.isCollapse)
    }
  }
}
</script>
