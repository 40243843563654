<template>
  <el-button type="danger" @click="handleClick" plain>移动到回收站</el-button>
</template>

<script>
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  props: {
    padIds: Array
  },
  methods: {
    async handleClick () {
      console.log('padIDs:', this.padIds)
      try {
        await this.$confirm('确认移动到回收站？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: async (action, instance, done) => {
            if (action !== 'confirm') {
              done()
              return
            }
            instance.showCancelButton = false
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '移动中'
            setTimeout(async () => {
              try {
                await api.throwPadsToTrash(this.padIds)
                this.$emit('trashed')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                    this.$message.success('移动成功！')
                  }, 300)
                }, 300)
              } catch (error) {
                console.log('error:', error)
                dealOtherError(this, error)
              }
            }, 1000)
          }
        })
      } catch {
        this.$message.info('已取消删除')
      }
    }
  }
}
</script>

<style scoped>

</style>
