<template>
  <el-button type="primary" @click="handleClick" plain>新建文档</el-button>
</template>

<script>
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  methods: {
    async handleClick () {
      try {
        await this.$prompt('文档名称', '新建文档', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: '未命名文档',
          inputPattern: /^.{1,50}$/,
          inputErrorMessage: '文档名称太短或太长 ',
          beforeClose: async (action, instance, done) => {
            if (action !== 'confirm') {
              done()
              return
            }
            instance.showCancelButton = false
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '创建中'
            const padName = instance.inputValue
            setTimeout(async () => {
              try {
                const { data } = await api.createPad(padName)
                done()
                instance.confirmButtonLoading = false
                this.$emit('created')
                const { href } = this.$router.resolve({ name: 'Pad', params: { token: data.padID } })
                window.open(href, '_blank')
              } catch (error) {
                console.log('error:', error)
                dealOtherError(this, error)
              }
            }, 1000)
          }
        })
      } catch {
        this.$message.info('取消创建文档')
      }
    }
  }
}
</script>

<style scoped>

</style>
