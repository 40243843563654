<template>
  <BaseListMain
    v-bind="listData"
    @selection-change="handleSelectionChange"
  >
    <template v-slot:header>
      <base-row justify="space-between" fullWidth>
        <span>回收站</span>
      </base-row>
    </template>
    <template v-slot:footer>
      <base-row justify="start">
        <BaseButtonRestorePads :pad-ids="selectedPadIDs" @restored="handleRefresh(0)" />
        <BaseButtonDeletePads :pad-ids="selectedPadIDs" @deleted="handleRefresh(0)" />
      </base-row>
    </template>
  </BaseListMain>
</template>

<script>
import BaseListMain from '@/components/BaseListMain.vue'
import BaseButtonDeletePads from '@/components/BaseButtonDeletePads'
import BaseButtonRestorePads from '@/components/BaseButtonRestorePads'
import api from '@/api'
import { dealOtherError } from '@/api/error'

export default {
  components: {
    BaseListMain,
    BaseButtonDeletePads,
    BaseButtonRestorePads
  },
  data () {
    return {
      listData: {
        tableData: [],
        firstColumnLabel: '文档',
        tableColumns: [
          {
            prop: 'owner',
            label: '归属者'
          },
          {
            prop: 'time',
            label: '删除时间'
          }
        ],
        loading: true,
        emptyLabel: '回收站为空',
        canOpen: false
      },
      selectedPadIDs: []
    }
  },
  mounted () {
    this.listData.loading = true
    this.getList()
  },
  methods: {
    handleSelectionChange (val) {
      console.log('selection change: ', val)
      this.selectedPadIDs = val.map(item => item.padID)
    },
    async getList () {
      try {
        const resp = await api.listTrashPads()
        const metas = resp.data.padMetas
        const { data: { userInfo } } = await api.getUserSelfInfo()
        const tableData = []
        metas.forEach(meta => {
          tableData.push({
            name: meta.padName,
            padID: meta.padID,
            icon: 'el-icon-document',
            owner: userInfo.nickname,
            time: (new Date(meta.trashTime)).toLocaleString()
          })
        })
        this.listData.tableData = tableData
        setTimeout(() => {
          this.listData.loading = false
        }, 500)
      } catch (error) {
        setTimeout(() => {
          dealOtherError(this, error)
          this.listData.emptyLabel = '出错了！尝试刷新一下'
          this.listData.loading = false
        }, 500)
      }
    },
    handleRefresh (timeout = 3000) {
      setTimeout(() => this.getList(), timeout)
    }
  }
}
</script>

<style scoped>

</style>
