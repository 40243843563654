import { render, staticRenderFns } from "./BaseButtonNewPad.vue?vue&type=template&id=0db6515e&scoped=true&"
import script from "./BaseButtonNewPad.vue?vue&type=script&lang=js&"
export * from "./BaseButtonNewPad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db6515e",
  null
  
)

export default component.exports