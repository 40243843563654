<template>
  <el-container class="home-main">
    <el-header>
      <base-row>
        <slot name="header" />
      </base-row>
    </el-header>
    <el-main class="list-main">
      <el-skeleton style="height: 100%" :count="4" animated :loading="loading">
        <template slot="template">
          <el-skeleton-item style="height: 50px"></el-skeleton-item>
          <div style="height: 10px"></div>
        </template>
        <template>
          <BaseList v-if="tableData.length > 0"
            :tableData="tableData"
            :firstColumnLabel="firstColumnLabel"
            :tableColumns="tableColumns" :can-open="canOpen"
            @selection-change="handleSelectionChange"
            @open="handleOpen"
          />
          <el-empty :image="NoContentImg" :image-size="300" v-else :description="emptyLabel"></el-empty>
        </template>
      </el-skeleton>
    </el-main>
    <el-footer v-show="computedShowFooter">
      <base-row>
        <slot name="footer" />
      </base-row>
    </el-footer>
  </el-container>
</template>

<script>
import BaseList from '@/components/BaseList'
import NoContentImg from '@/assets/no_content.png'

export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    firstColumnLabel: {
      type: String,
      required: true
    },
    tableColumns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    emptyLabel: {
      type: String,
      default: '无'
    },
    canOpen: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseList
  },
  data () {
    return {
      isShowFooter: false,
      NoContentImg
    }
  },
  computed: {
    computedShowFooter () {
      return this.isShowFooter && this.tableData.length > 0
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.$emit('selection-change', val)
      this.isShowFooter = val.length > 0
    },
    handleOpen () {
      this.$emit('open')
    }
  }
}
</script>

<style scoped>
.home-main {
  height: 100%;
}
.list-main {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
.el-header {
  display: flex;
}
.el-footer {
  display: flex;
}
</style>
